import { Outlet, Navigate } from "react-router-dom";
import { useStateValue } from "../context/StateProvider";

// Admin için korumalı rota bileşeni
const AdminProtectedRoute = () => {
    const [{ user, userIdhasAccess }] = useStateValue();

    if (user && user.uid === userIdhasAccess) {
        return <Outlet />; // Admin yetkili kullanıcılar için bileşenleri render et
    } else if (user) {
        return <Navigate to="/user" />; // Yetkisi olmayan kullanıcıyı kullanıcı sayfasına yönlendir
    } else {
        return <Navigate to="/" />; // Giriş yapmamış kullanıcıyı ana sayfaya yönlendir
    }
};

// Kullanıcı için korumalı rota bileşeni
const UserProtectedRoute = () => {
    const [{ user, userIdhasAccess }] = useStateValue();

    if (user && user.uid !== userIdhasAccess) {
        return <Outlet />; // Kullanıcı yetkili kullanıcılar için bileşenleri render et
    } else if (user) {
        return <Navigate to="/admin" />; // Admin yetkili kullanıcıyı admin sayfasına yönlendir
    } else {
        return <Navigate to="/" />; // Giriş yapmamış kullanıcıyı ana sayfaya yönlendir
    }
};

export { AdminProtectedRoute, UserProtectedRoute };
