import { useEffect, useState, useRef } from "react";
import {
  signInWithPopup,
  signOut,
  browserSessionPersistence,
  setPersistence,
} from "firebase/auth";
import { firebaseAuth, provider } from "../firebase-config";
import { useStateValue } from "../context/StateProvider";
import { actionType } from "../context/reducer";
import { saveUserToFirestore, getUserFromFirestore } from "../utils/FirebaseFunction";
import { useNavigate } from "react-router-dom";
import AudioPlayer from "./Audioplayer";
import CryptoJS from "crypto-js"; // CryptoJS kütüphanesini import et

// User ID'yi hashlemek için bir fonksiyon
const hashUserId = (userId) => {
  // SHA-256 algoritmasıyla hashle
  return CryptoJS.SHA256(userId).toString(CryptoJS.enc.Base64); // Hashi base64 formatında döndür
};

function LoginForm({ buttonClass }) {
  const [{ user, userIdhasAccess }, dispatch] = useStateValue();
  const [isLoggedin, setIsLoggedin] = useState(false);
  const navigate = useNavigate();

  const audioRef = useRef(null); // Audio referansı

  useEffect(() => {
    setIsLoggedin(!!user);
  }, [user]);

  useEffect(() => {
    if (user) {
      // Kullanıcı giriş yaptıysa ve /user sayfasına yönlendirdiysek müziği çal
      if (audioRef.current) {
        audioRef.current.play().catch((error) => {
          console.error("Müzik çalma hatası:", error);
        });
      }
    }
  }, [user]); // user değiştiğinde müziği çal

  const loginlogout = async () => {
    if (!user) {
      try {
        await setPersistence(firebaseAuth, browserSessionPersistence);
        const result = await signInWithPopup(firebaseAuth, provider);
        const providerData = result.user.providerData[0];

        // Kullanıcı ID'sini hashle
        const hashedUserId = hashUserId(providerData.uid);

        // Check if user already exists in Firestore
        const firestoreUser = await getUserFromFirestore(providerData.uid);

        if (firestoreUser) {
          // If user exists in Firestore, dispatch existing data
          dispatch({
            type: actionType.SET_USER,
            user: firestoreUser,
          });
        } else {
          // If user doesn't exist in Firestore, save them with hash
          await saveUserToFirestore({
            ...providerData,
            hashedUserId, // Hashlenmiş userId'yi ekle
          });
          dispatch({
            type: actionType.SET_USER,
            user: {...providerData,hashedUserId}
          });
        }

        // Navigate based on user access level
        if (providerData.uid === userIdhasAccess) {
          navigate("/admin");
        } else {
          navigate("/user");
        }
      } catch (error) {
        console.error("Sign-in error:", error);
      }
    } else {
      try {
        await signOut(firebaseAuth);
        localStorage.removeItem("firebase:authUser:");
        sessionStorage.removeItem("firebase:authUser:");

        dispatch({
          type: actionType.SET_USER,
          user: null,
        });

        navigate("/");
      } catch (error) {
        console.error("Sign-out error:", error);
      }
    }
  };

  return (
    <>
      <AudioPlayer ref={audioRef} />

      <button
        onClick={loginlogout}
        className={`px-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 ${buttonClass}`}
      >
        {isLoggedin ? "Çıkış Yap" : "Giriş Yap"}
      </button>
    </>
  );
}

export default LoginForm;
