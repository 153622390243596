import React, { createContext, useState, useContext } from 'react';

// Context'i oluşturuyoruz
const UserContext = createContext();

// Provider bileşeni, kullanıcı verilerini sağlayacak
export const UserProvider = ({ children }) => {
  const [subscriberInfos, setSubscriberInfos] = useState(null); // Kullanıcı bilgilerini state'de tutuyoruz

  // Kullanıcı bilgilerini güncellemek için bir fonksiyon
  const setUserInfo = (userData) => {
    setSubscriberInfos(userData);
  };

  return (
    <UserContext.Provider value={{ subscriberInfos, setUserInfo }}>
      {children}
    </UserContext.Provider>
  );
};

// Kullanıcı bilgilerine erişim sağlayan custom hook
export const useUser = () => {
  return useContext(UserContext);
};
