import React from 'react';
import { Link } from 'react-router-dom';

const Missing = () => {  
    const styles = {
        container: {
          textAlign: 'center',
          marginTop: '50px',
        },
        heading: {
          fontSize: '48px',
          marginBottom: '20px',
        },
        text: {
          fontSize: '18px',
          marginBottom: '20px',
        },
        link: {
          fontSize: '16px',
          textDecoration: 'none',
          color: '#007bff',
        }
      };
        
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404 - Page Not Found</h1>
      <p style={styles.text}>Oops! The page you are looking for doesn't exist.</p>
      <Link to="/" style={styles.link}>Go back to Home</Link>
    </div>
  );
    
}

export default Missing



