import { useRef } from "react";
import AudioPlayer from "./Audioplayer";
import LoginForm from "./LoginForm";

const Home = () => {
    return (
        <>
            
            <div className="min-h-screen flex items-center justify-center bg-gray-100">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
                    <h1 className="text-3xl font-bold text-center mb-6">Tekrar Hoşgeldiniz!</h1>
                    <p className="text-gray-600 text-center mb-8">Lütfen hesabınız ile giriş yapınız.</p>
                    <div className="flex justify-center">
                    <LoginForm />   </div>               
                </div>
            </div>
        </>
    );
};

export default Home;
