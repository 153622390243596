import React, { forwardRef } from 'react';

const AudioPlayer = forwardRef((props, ref) => {
  return (
    <audio ref={ref} src={`${process.env.PUBLIC_URL}/music/AO_isvv.mp3`} loop>
      Your browser does not support the audio tag.
    </audio>
  );
});

export default AudioPlayer;
