import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useStateValue } from "../context/StateProvider";
import { 
  fetchTargetUserSubscriptionStatus, 
  uploadFilesToStorage, 
  fetchTargetUserContent, 
  deleteFileFromFirestoreAndStorage,
  requestSubscriptionApproval,
  getUserFileCount, 
  getContentPageLinkAndQr,
  subscribeToUserContent
} from '../utils/FirebaseFunction';
import LoginForm from './LoginForm';
import { useUser } from './context/UserContext'; // useUser hook'unu içeri aktarın

const SubscriberComponent = () => {
  const [{ user }] = useStateValue();
  const [videoFile, setVideoFile] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [uploadedContent, setUploadedContent] = useState([]);
  const [expiryDate, setExpiryDate] = useState('Lütfen Üye Olunuz');
  const [fileCount, setFileCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const videoInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const [contentPageLink, setContentPageLink] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const { subscriberInfos } = useUser();
  const targetUserId = subscriberInfos.id;

  
  
  useEffect(() => {
    if (user?.uid) {
      const fetchSubscriptionAndContent = async () => {
        const [subscriptionStatus, content] = await Promise.all([
          fetchTargetUserSubscriptionStatus(user.uid, targetUserId),
          fetchTargetUserContent(user.hashedUserId, targetUserId)
        ]);
        
        setHasSubscription(subscriptionStatus.isActive); 
        setUploadedContent(content);
        setExpiryDate(subscriptionStatus.expiryDate 
          ? new Date(subscriptionStatus.expiryDate).toLocaleDateString('tr-TR') 
          : 'Lütfen üye olunuz');
      };
      fetchSubscriptionAndContent();
    }
  }, [user?.uid, subscriberInfos]);
  

  useEffect(() => {
    if (user?.uid) {
      const fetchFileCount = async () => {
        const count = await getUserFileCount(user.hashedUserId
          , targetUserId);
        setFileCount(count);
      };
      fetchFileCount();

      const fetchContentPageLink = async () => {
        const contentPageLinkAndQr = await getContentPageLinkAndQr(user.uid, targetUserId);
        setContentPageLink(contentPageLinkAndQr.contentPageLink);
        setQrCodeUrl(contentPageLinkAndQr.contentPageQr);
      };
      fetchContentPageLink();
    }
  }, [user?.uid]);


  const handleVideoChange = (event) => setVideoFile(event.target.files[0]);

  const handleImageChange = (event) => setImageFiles(Array.from(event.target.files));

  const handleUpload = async () => {
    if (!targetUserId) return console.error("Target User ID is not defined. Please ensure the target user is created.");
    if (!hasSubscription) return console.error("You need an active subscription to upload content.");

    const totalFiles = (videoFile ? 1 : 0) + imageFiles.length;
    if (fileCount + totalFiles > 5) return console.error("You can upload a maximum of 5 files.");
    if (!videoFile && imageFiles.length === 0) return console.error("Please select at least one file to upload.");

    setLoading(true);
    try {
      const { contentId, videoPath, imagePaths } = await uploadFilesToStorage(user.hashedUserId, targetUserId, videoFile, imageFiles);
      
      const newContent = {
        id: contentId,
        userId: user.uid,
        targetUserId: targetUserId,
        videoPath: videoPath || null,
        imagePaths: imagePaths,
        createdAt: new Date().toISOString()
      };

      setUploadedContent(prevContent => [...prevContent, newContent]);
      setVideoFile(null);
      setImageFiles([]);
      videoInputRef.current.value = "";
      imageInputRef.current.value = "";

      setFileCount(prevCount => prevCount + totalFiles);

      setUploadMessage('Content uploaded successfully!');
      setTimeout(() => setUploadMessage(''), 3000);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (contentId, filePath) => {
    try {
      await deleteFileFromFirestoreAndStorage(contentId, targetUserId, user.hashedUserId, filePath);
      const updatedFileCount = await getUserFileCount(user.hashedUserId, targetUserId);
      setFileCount(updatedFileCount);

      const updatedContent = await fetchTargetUserContent(user.hashedUserId, targetUserId);
      setUploadedContent(updatedContent);

    } catch (error) {
      console.error("Error deleting content:", error);
    }
  };

  // console.log("uploaded Content : ", uploadedContent)
  // console.log("subscriberInfos : ", subscriberInfos)
  console.log("user : ", user)

  return (
    <>
      <LoginForm buttonClass="absolute top-2 right-2" />
      <div className="max-w-4xl mx-auto mt-4 p-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-4">Hoşgeldiniz Sayın, {user.displayName}</h1>
        <p className="text-lg">
          Üyeliklik Alınan Kişi: <span className="font-semibold">{subscriberInfos.name} {subscriberInfos.surname}</span>
          
        </p>
        <p>{subscriberInfos.id}</p>
        <p className="text-lg">
          Üyelik durumu: <span className="font-semibold">{hasSubscription ? 'Üye' : 'Üye değil'}</span>
          
        </p>
        <p className="text-lg mb-4">
          Üyelik sonlanma tarihi: <span className="font-semibold">{expiryDate}</span>
        </p>
        {/* <button
          onClick={handleRequestSubscription}
          className="w-full py-2 px-4 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200 mb-4"
          style={{ display: hasSubscription ? 'none' : 'block' }}
          disabled={hasSubscription}
        >
          1 yıllık üyelik al
        </button> */}
        <div>
          







          {contentPageLink ? (<>
          <h2>İçerik Sayfanızın Linki</h2>
          <p>Bu Linki Paylaşabilirsiniz: <a className='text-center font-bold hover:bg-green-300' href={contentPageLink} target="_blank" rel="noopener noreferrer">{contentPageLink}</a></p></>
          ) : (
          <p></p>
          )}

        {qrCodeUrl && (
        <div className="text-center">
          <p className="text-lg mb-4">İçerik sayfasına erişmek için QR kodunu tarayın:</p>
          <div className="flex justify-center">
            <img src={qrCodeUrl} alt="User Content QR Code" className="w-32 h-32" />
          </div>
        </div>
      )}
        </div>
        <div style={{ display: !hasSubscription ? 'none' : 'block' }}>
          <div className="mb-4">
            <label className="block mb-2">Video Yükleyin:</label>
            <input
              type="file"
              accept="video/*"
              ref={videoInputRef}
              onChange={handleVideoChange}
              className="block w-full text-sm text-gray-500 border border-gray-300 rounded-lg cursor-pointer hover:border-blue-500 focus:outline-none focus:ring focus:ring-green-200"
              disabled={!hasSubscription}
            />
          </div>

          <div className="mb-4">
            <label className="block mb-2">Resim Yükleyin:</label>
            <input
              type="file"
              accept="image/*"
              multiple
              ref={imageInputRef}
              onChange={handleImageChange}
              className="block w-full text-sm text-gray-500 border border-gray-300 rounded-lg cursor-pointer hover:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200"
              disabled={!hasSubscription}
            />
          </div>

          <button
            onClick={handleUpload}
            className="w-full py-2 px-4 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200 mb-2"
            disabled={!hasSubscription}
          >
            İçerik Yükle
          </button>
        </div>
        {loading && (
          <div className="flex justify-center my-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-green-600"></div>
          </div>
        )}

        {uploadMessage && (
          <div className="text-green-600 font-semibold text-center my-4">
            {uploadMessage}
          </div>
        )}
      </div>

      {/* Listeme Modülü */}
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        {/* <h2 className="text-xl font-bold mt-6 mb-4">İçerik Yükleyin</h2> */}
        <ul className="space-y-4">
          {uploadedContent
            // Sort to prioritize items with a videoPath
            .sort((a, b) => (b.videoPath ? 1 : 0) - (a.videoPath ? 1 : 0))
            .map((content) => (
              <li key={content.id} className="flex flex-col border border-gray-300 rounded-lg p-4 mb-4">
                
                {/* Display the video first if it exists */}
                {content.videoPath && (
                  <div className="flex items-start mb-4">
                    <video className="w-32 h-32 object-cover rounded-md mr-4" controls>
                      <source src={content.videoPath} type="video/mp4" />
                    </video>
                    <button
                      onClick={() => handleDelete(content.id, content.videoPath)}
                      className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600 transition duration-200"
                    >
                      Sil
                    </button>
                  </div>
                )}

                {/* Display images if they exist */}
                {content.imagePaths && content.imagePaths.map((imagePath, idx) => (
                  <div key={idx} className="flex items-start mb-4">
                    <img src={imagePath} alt={`image-${idx}`} className="w-32 h-32 object-cover rounded-md mr-4" />
                    <button
                      onClick={() => handleDelete(content.id, imagePath)}
                      className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600 transition duration-200"
                    >
                      Sil
                    </button>
                  </div>
                ))}
              </li>
            ))}
        </ul>
      </div>

    </>
  );
};

export default SubscriberComponent;
