import React, { useState, useEffect, createContext, useContext } from "react";
import { Link } from 'react-router-dom';
import LoginForm from "./LoginForm";
import { useStateValue } from "../context/StateProvider";
import { requestSubscriptionApproval, checkSubscriptionStatus, fetchTargetUser, fetchSubscribers } from '../utils/FirebaseFunction'; // Ensure these functions are correctly implemented
import { hashString } from "./utilities";
import { useUser } from './context/UserContext'; // useUser hook'unu içeri aktarın

const UserPage = () => {
  const [targetUserName, setTargetUserName] = useState(""); // Subscriber's first name
  const [targetUserSurname, setTargetUserSurname] = useState("");  // Subscriber's last name
  const [targetUserId, setTargetUserId] = useState(null)
  const [targetUserSubscriptionRequestStatus, setTargetUserSubscriptionRequestStatus] = useState(null);  // To show request status (pending, success, error)
  const [targetUserStatus, setTargetUserStatus] = useState(null);  // To show if an active subscription already exists
  const [subscribers, setSubscribers] = useState([]); // State to store fetched subscribers
  const [message, setMessage] = useState("");  // Store the message returned from the subscription request
  const [{ user }] = useStateValue();
  const { subscriberInfos, setUserInfo } = useUser()

  console.log("Tepede user.hashedUserId : ", user.hashedUserId)

  // Fetch subscribers on component mount
  useEffect(() => {
    const getSubscribers = async () => {
      try {
        const fetchedSubscribers = await fetchSubscribers(user.uid);
        setSubscribers(fetchedSubscribers);
        // setUserInfo(fetchedSubscribers); //Use Context'e bilgileri göndermek
      } catch (error) {
        console.error("Error fetching subscribers:", error);
      }
    };

    getSubscribers();
  }, []);

  const handleSubscriberClick = (subscriber) => {
    setUserInfo(subscriber); // Set only the clicked subscriber's info in the context
  };

  console.log("subscribers : ",subscribers)

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();  // Convert Firestore Timestamp to Date
    return date.toLocaleDateString('tr-TR');  // Format the date as a string
  };

  // Handle subscription request  
  const handleRequestSubscription = async () => {
    if (targetUserName && targetUserSurname) {
      const generatedTargetUserId = hashString(`${targetUserName}-${targetUserSurname}`);
      setTargetUserId(generatedTargetUserId);
      // console.log("Generated targetUserId:", generatedTargetUserId);


  
      // Helper function to get subscription status and target user existence
      const checkStatus = async () => {
        try {
          const subscriptionStatus = await checkSubscriptionStatus(generatedTargetUserId);
          console.log("subscriptionStatus:", subscriptionStatus);
  
          const targetUserExists = await fetchTargetUser(user.uid, generatedTargetUserId);
          console.log("targetUserStatus:", targetUserExists);
  
          return { subscriptionStatus, targetUserExists };
        } catch (error) {
          console.error("Error checking target user's subscription status:", error);
          return { subscriptionStatus: false, targetUserExists: false };
        }
      };
  
      // Call checkStatus and use the returned values directly
      const { subscriptionStatus, targetUserExists } = await checkStatus();
  
      if (subscriptionStatus) {
        setMessage(`Şu an ${targetUserName} ${targetUserSurname} için admin onayında bekleyen abonelik talebiniz var. Onay veya ret verilmeden tekrar talep oluşturulamaz.`);
        setTimeout(() => setMessage(""), 7000);
        return;
      } else if (targetUserExists) {
        setMessage("Bu kullanıcı için geçerli bir abonelik var.");
        setTimeout(() => setMessage(""), 5000);
        return;
      } else {
        try {
          const result = await requestSubscriptionApproval(user.uid, user.hashedUserId, user.displayName, targetUserName, targetUserSurname, generatedTargetUserId);
          
          console.log("TRY içinde user.hashedUserId : ", user.hashedUserId)
          
          setMessage(result.message);
          console.log(`Subscription request sent for: ${targetUserName} ${targetUserSurname}`);
  
          setTimeout(() => setMessage(""), 5000);
        } catch (error) {
          setMessage("Bir hata oluştu. Lütfen tekrar deneyin.");
          console.error("Error during subscription request:", error);
        }
      }
    } else {
      message("Lütfen isim ve soyisim giriniz.");
      setTimeout(() => setMessage(""), 5000);
    }
  };    

  return (
    <>
      <LoginForm buttonClass="absolute top-2 right-2" />
      <div className="p-6 mt-8 rounded-lg shadow-lg text-black">
        {/* Subscription Request Form */}
        <div>
          <h2 className="text-xl font-semibold text-center mb-4">Abonelik almak istediğiniz kişinin isim ve soyismini giriniz.</h2>
        </div>
        <div className="mt-4 mb-4">
          <label className="block text-sm font-medium text-black mb-1">Abone İsmi:</label>
          <input
            type="text"
            value={targetUserName}
            onChange={(e) => setTargetUserName(e.target.value)}
            className="block w-full text-md text-black border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="İsim"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-black mb-1">Abone Soyismi:</label>
          <input
            type="text"
            value={targetUserSurname}
            onChange={(e) => setTargetUserSurname(e.target.value)}
            className="block w-full text-md text-black border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Soyisim"
          />
        </div>
        <button
          onClick={handleRequestSubscription}
          className="w-full py-2 px-4 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200 mb-2"
          disabled={!targetUserName || !targetUserSurname}
        >
          1 yıllık abonelik al
        </button>
        {message && (
          <p className={`text-center ${targetUserStatus ? 'bg-green-300' : 'bg-red-300'} font-semibold`}>
            {message}
          </p>
        )}

        {/* Subscriber List */}
        <div className="mt-8">
          <h3 className="text-lg font-semibold mb-4">Mevcut Aboneler</h3>
          <table className="w-full text-left border border-gray-300">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">Abone İsmi</th>
                <th className="px-4 py-2 border-b">Abone Soyismi</th>
                <th className="px-4 py-2 border-b">Abonelik Bitiş Tarihi</th>
                <th className="px-4 py-2 border-b">Yüklenen İçerik</th>
              </tr>
            </thead>
            <tbody>
              {subscribers.map((subscriber) => {
                console.log("subscriber : ", subscriber);  // Correctly placed console.log

                return (
                  <tr key={subscriber.id}>
                    <td className="px-4 py-2 border-b">{subscriber.name}</td>
                    <td className="px-4 py-2 border-b">{subscriber.surname}</td>
                    <td className="px-4 py-2 border-b">{formatDate(subscriber.subscriptionEndDate)}</td>
                    <td className="px-4 py-2 border-b">
                      <Link 
                        to={`/userContent/${user.hashedUserId}/${subscriber.id}/content`}                                       
                        className="text-blue-600 hover:underline"                       
                        onClick={() => handleSubscriberClick(subscriber)}
                      >
                        Yüklenen İçerik
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>


          </table>
        </div>
      </div>
    </>
  );
};

export default UserPage;
