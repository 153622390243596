import React from 'react';
import ReactDOM from 'react-dom/client'; 
import App from './App'; 
import { StateProvider } from './context/StateProvider';
import { initialState } from './context/initialState';
import reducer from './context/reducer';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Router>
    <StateProvider initalState={initialState} reducer={reducer}>
      <App />
    </StateProvider>
  </Router>
</React.StrictMode>
);
 