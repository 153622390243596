import { Routes, Route } from 'react-router-dom';
import { UserProvider } from './components/context/UserContext'; // UserContext'i içeri aktarın
import Home from './components/Home';
import Missing from './components/Missing';
import AdminPage from './components/AdminPage';
import { AdminProtectedRoute, UserProtectedRoute } from './utils/ProtectedRoutes'; // Koruma rotalarını içe aktar
import UserPage from './components/UserPage'; // Kullanıcı sayfasını içe aktar
import ContentPage from './components/ContentPage';
import SubscriberComponent from './components/SubscriberComponent';

function App() { 

  return (
    <>    
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/content/:hashedUserId/:targetUserId" element={<ContentPage />} />

        {/* Protected Routes for Admin */}
        <Route element={<AdminProtectedRoute />}> 
          <Route path="/admin" element={<AdminPage />} />

        </Route>

        {/* Protected Routes for Users */}
       
        <Route element={<UserProtectedRoute />}>
          <Route path="/user" element={<UserProvider><UserPage /></UserProvider>} />
          <Route path="/userContent/:hashedUserId/:targetUserId/content" element={<UserProvider><SubscriberComponent /></UserProvider>} />
        </Route>
      
        {/* Catch All for Non-Existing Routes */}
        <Route path="*" element={<Missing />} />
      </Routes>
    </>
  );
}

export default App;
