import {
  collection,
  doc,
  getDocs,
  addDoc,
  orderBy,
  query,
  setDoc,
  deleteDoc,
  updateDoc,
  increment,
  where,
  getDoc,
  arrayRemove,
  deleteField,
  Timestamp,
  onSnapshot
} from "firebase/firestore";

import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll } from "firebase/storage";
import { firestore, storage } from "../firebase-config";
import { v4 as uuidv4 } from 'uuid'; // UUID için
import QRCode from "qrcode";
import { hashString } from "../components/utilities";

// Save user information to Firestore
export const saveUserToFirestore = async (providerData) => {
  try {
    await setDoc(doc(firestore, "users", providerData.uid), providerData, { merge: true });
    console.log("User saved successfully:", providerData);
  } catch (error) {
    console.error("Error saving user to Firestore:", error);
  }
};

// Upload files to Firebase Storage and save metadata in Firestore
export const uploadFilesToStorage = async (hashedUserId, targetUserId, videoFile, imageFiles) => {
  const maxFiles = 5;

  // Fetch user's existing content count
  const userContent = await fetchTargetUserContent(hashedUserId, targetUserId);
  const existingContentCount = userContent.length;

  if (existingContentCount + (videoFile ? 1 : 0) + imageFiles.length > maxFiles) {
    throw new Error(`Kullanıcı yalnızca ${maxFiles} dosya yükleyebilir. Lütfen içerik siliniz.`);
  }

  // Upload files
  const videoURL = videoFile ? await uploadVideo(storage, hashedUserId, targetUserId, videoFile) : null;
  const imageURLs = imageFiles.length > 0 ? await uploadImages(storage, hashedUserId, targetUserId, imageFiles) : [];

  console.log("Uploaded videoURL:", videoURL);
  console.log("Uploaded imageURLs:", imageURLs);

  // Generate public URLs
  const videoPublicURL = videoURL ? await getPublicURL(videoURL) : null;
  const imagePublicURLs = await Promise.all(imageURLs.map(getPublicURL));

  console.log("Generated public video URL:", videoPublicURL);
  console.log("Generated public image URLs:", imagePublicURLs);

  // Save content metadata in Firestore
  const contentRef = await addDoc(collection(firestore, `userContent/${hashedUserId}/targetUsers/${targetUserId}/content`), {
    videoPath: videoPublicURL,
    imagePaths: imagePublicURLs,
    createdAt: new Date().toISOString()
  });

  console.log("Content uploaded successfully with ID:", contentRef.id);

  return {
    contentId: contentRef.id,
    videoPath: videoPublicURL,
    imagePaths: imagePublicURLs
  };
};


// Function to get public URL for a file
const getPublicURL = async (filePath) => {
  const fileRef = ref(storage, filePath); // Get a reference to the file
  const publicURL = await getDownloadURL(fileRef); // Get the public URL
  return publicURL;
};

// Helper function to upload video
const uploadVideo = async (storage, hashedUserId, targetUserId, videoFile) => {
  const videoRef = ref(storage, `videos/${hashedUserId}/targetUsers/${targetUserId}/${uuidv4()}_${videoFile.name}`);
  await uploadBytes(videoRef, videoFile);
  return await getDownloadURL(videoRef);
};

// Helper function to upload images
const uploadImages = async (storage, hashedUserId, targetUserId, imageFiles) => {
  const imageRefs = imageFiles.map(file => ref(storage, `images/${hashedUserId}/targetUsers/${targetUserId}/${uuidv4()}_${file.name}`));
  const imageURLs = await Promise.all(imageRefs.map(async (imageRef, idx) => {
    await uploadBytes(imageRef, imageFiles[idx]);
    return await getDownloadURL(imageRef);
  }));
  return imageURLs;
};

// Fetch user uploaded content from Firestore with real-time updates
export const fetchTargetUserContent = async (hashedUserId, targetUserId) => {

  try {

    console.log("fetchUserContent çalıştı...")
    console.log("userId : ", hashedUserId , "targetUserId : ", targetUserId)

    const querySnapshot = await getDocs(query(collection(firestore, `userContent/${hashedUserId}/targetUsers/${targetUserId}/content`)));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching user content:", error);
    throw error;
  }
};

// Fetch specific targetUser from Firestore and check if targetUserId exists
export const fetchTargetUser = async (userId, targetUserId) => {
  try {
    // Fetch target users from Firestore
    const snapshot = await getDocs(collection(firestore, `users/${userId}/targetUsers`));
    const targetUserExists = snapshot.docs.some(doc => {
    console.log("doc.id:", doc.id);
    return doc.id === targetUserId;
  });
    return targetUserExists;
  } catch (error) {
    console.error("Abonelik talepleri alınırken hata oluştu:", error);
    throw error;
  }
};


// Update content in Firestore (if needed)
// export const updateContentInFirestore = async (userId, targetUserId, contentId, updatedData) => {
//   try {
//     const contentRef = doc(firestore, `userContent/${userId}/targetUsers/${targetUserId}/content`, contentId);
//     await updateDoc(contentRef, updatedData);
//     console.log("Content updated successfully:", contentId);
//   } catch (error) {
//     console.error("Error updating content:", error);
//     throw error;
//   }
// };

// Get user data from Firestore
export const getUserFromFirestore = async (userId) => {
  try {
    const userRef = doc(firestore, "users", userId); // Assuming users are stored under 'users' collection
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      return { id: userDoc.id, ...userDoc.data() }; // Return user data
    } else {
      console.log("No such user!");
      return null; // User not found
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Re-throw error for handling
  }
};

export const getUserFileCount = async (hashedUserId, targetUserId) => {
  // console.log(`Fetching file count for user: ${userId}`);

  try {
      // Reference to the user's content collection
      const contentRef = collection(firestore, `userContent/${hashedUserId}/targetUsers/${targetUserId}/content`);
      
      // Get the documents in the collection
      const snapshot = await getDocs(contentRef);
      let totalCount = 0;

      // Iterate through each document and count the files
      snapshot.forEach(doc => {
          const data = doc.data();
          // Count video files if present
          if (data.videoPath) {
              totalCount += 1; // Increment for each video
          }
          // Count image files if present
          if (Array.isArray(data.imagePaths)) {
              totalCount += data.imagePaths.length; // Increment by number of images
          }
      });

      return totalCount; // Return the total count of files
  } catch (error) {
      console.error("Error fetching user file count:", error);
      return 0; // Return 0 in case of an error
  }
  
};

export const deleteFileFromFirestoreAndStorage = async (contentId, targetUserId, hashedUserId, filePath) => {
  const storage = getStorage();
  try {
    const contentRef = doc(firestore, "userContent", hashedUserId, "targetUsers", targetUserId, "content", contentId);
    const contentSnap = await getDoc(contentRef);
    console.log("contentSnap : ", contentSnap)

    if (contentSnap.exists()) {
      const contentData = contentSnap.data();
      console.log("Fetched Content Data:", contentData);

      let updatedImagePaths = contentData.imagePaths || [];
      let updatedVideoPath = contentData.videoPath || null;

      // Video silme işlemi
      if (contentData.videoPath && contentData.videoPath === filePath) {
        const videoRef = ref(storage, filePath);
        await deleteObject(videoRef);
        console.log("Video deleted:", filePath);

        // Firestore'dan videoPath alanını kaldır
        await updateDoc(contentRef, { videoPath: deleteField() });
        console.log(`videoPath field deleted successfully from content with ID: ${contentId}`);
        updatedVideoPath = null;
      }

      // Görüntüleri silme işlemi
      if (contentData.imagePaths) {
        const deleteImagePromises = contentData.imagePaths.map(async (imagePath) => {
          if (imagePath === filePath) {
            const imageRef = ref(storage, imagePath);
            await deleteObject(imageRef);
            console.log("Image deleted:", imagePath);
          }
        });
        await Promise.all(deleteImagePromises);

        // Firestore'dan imagePaths dizisindeki ilgili öğeyi kaldır
        updatedImagePaths = contentData.imagePaths.filter((imagePath) => imagePath !== filePath);
        await updateDoc(contentRef, { imagePaths: updatedImagePaths });
        console.log(`imagePaths field updated in Firestore document with ID: ${contentId}`);
      }

      // Eğer imagePaths boşsa ve videoPath null ise, contentId'yi sil
      if (updatedImagePaths.length === 0 && !updatedVideoPath) {
        await deleteDoc(contentRef);
        console.log(`Content with ID: ${contentId} deleted successfully.`);
      }

    } else {
      console.error("Content not found.");
    }
  } catch (error) {
    console.error("Error deleting content:", error);
    throw error;
  }
};

// Function to update user content metadata
export const updateContentMetadata = async (userId, targetUserId, updatedContentData) => {
  try {
    const userRef = doc(firestore, "users", userId, "targetUsers", targetUserId);
    
    // Update user's content metadata (this may vary depending on your Firestore structure)
    await updateDoc(userRef, {
      userContents: updatedContentData, // Güncellenmiş içerik bilgilerini buraya ekleyin
    });

    console.log('İçerik metadata güncellendi.');
  } catch (error) {
    console.error('Metadata güncellerken hata oluştu: ', error);
  }
};

// Fetch user's subscription status from Firestore
export const fetchTargetUserSubscriptionStatus = async (userId, targetUserId) => {
  try {
    const userRef = doc(firestore, "users", userId, "targetUsers", targetUserId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const { subscriptionEndDate } = userSnap.data();
      if (subscriptionEndDate && subscriptionEndDate.toDate() > new Date()) {
        return { isActive: true, expiryDate: subscriptionEndDate.toDate() };
      } else {
        return { isActive: false, expiryDate: null };
      }
    } else {
      console.error("Target User not found.");
      return { isActive: false, expiryDate: null };
    }
  } catch (error) {
    console.error("Error fetching subscription status:", error);
    throw error;
  }
};

// Request subscription approval from admin for another user
export const requestSubscriptionApproval = async (userId, hashedUserId, userName, targetUserName, targetUserSurname, targetUserId) => {
  try {
    // Reference to the subscriptionRequests collection
    const subscriptionRequestsRef = collection(firestore, "subscriptionRequests");
    await addDoc(subscriptionRequestsRef, {
      requesterId: userId,          // ID of the user making the request
      hashedUserId: hashedUserId,          
      requesterName: userName,           
      targetUserName,               // Name of the target user
      targetUserSurname,            // Surname of the target user
      targetUserId,                 // Deterministic unique ID for the target user
      status: "pending",
      requestDate: new Date(),
    });

    console.log("Subscription request sent for target user:", targetUserId);
    return { message: "Abonelik talebi iletildi.", status: "pending" };

  } catch (error) {
    console.error("Error requesting subscription approval:", error);
    throw error;
  }
};

// Bekleyen abonelik taleplerini getirme fonksiyonu
export const fetchPendingSubscriptionRequests = async () => {
  try {
    const snapshot = await getDocs(collection(firestore, "subscriptionRequests"));
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Abonelik talepleri alınırken hata oluştu:", error);
    throw error;
  }
};

// Approve subscription request for another user's subscription
export const approveSubscriptionRequest = async (requestId) => {
  try {
    // Retrieve the subscription request to get the target user's details
    const requestDocRef = doc(firestore, "subscriptionRequests", requestId);
    const requestDoc = await getDoc(requestDocRef);

    if (!requestDoc.exists()) {
      throw new Error("Subscription request not found");
    }

    const { requesterId, targetUserName, targetUserSurname, targetUserId } = requestDoc.data();

    // Reference the target user's document within the requester's targetUsers subcollection
    const targetUserDocRef = doc(firestore, "users", requesterId, "targetUsers", targetUserId);
    const targetUserDoc = await getDoc(targetUserDocRef);

    // If no existing subscription exists, create a new document
    if (!targetUserDoc.exists()) {
      await setDoc(targetUserDocRef, {
        name: targetUserName,
        surname: targetUserSurname,
        subscriptionStartDate: null,
        subscriptionEndDate: null,
      });
      console.log(`Created new document for target user ${targetUserName} ${targetUserSurname}.`);
    }

    // Set new subscription dates
    const startDate = Timestamp.now();
    const endDate = Timestamp.fromDate(new Date(startDate.toDate().setFullYear(startDate.toDate().getFullYear() + 1)));

    // Update the target user's document with the new subscription dates
    await updateDoc(targetUserDocRef, {
      subscriptionStartDate: startDate,
      subscriptionEndDate: endDate,
    });

    // Remove the subscription request from Firestore
    await deleteDoc(requestDocRef);
    console.log(`Subscription approved for ${targetUserName} ${targetUserSurname}, and subscription started.`);
  } catch (error) {
    console.error("Error approving subscription:", error);
    throw error;
  }
};

// Cancel subscription request
export const cancelSubscriptionRequest = async (requestId) => {
  try {
    // Retrieve the request to confirm details before deletion
    const requestDocRef = doc(firestore, "subscriptionRequests", requestId);
    const requestDoc = await getDoc(requestDocRef);

    if (!requestDoc.exists()) {
      throw new Error("Subscription request not found");
    }

    const { targetUserName, targetUserSurname } = requestDoc.data();

    // Delete the subscription request document from Firestore
    await deleteDoc(requestDocRef);
    console.log(`Subscription request for ${targetUserName} ${targetUserSurname} has been canceled.`);
  } catch (error) {
    console.error("Error canceling subscription request:", error);
    throw error;
  }
};

// Kullanıcı kaydolduğunda ContentPage linki oluştur ve QR kodu ekle
export const createUserContentPageLink = async (userId, hashedUserId, targetUserId) => {
  const contentPageLink = `http://msc.barruno.com/content/${hashedUserId}/${targetUserId}/`; // ContentPage linki oluştur
  // const contentPageLink = `http://localhost:3001/content/${hashedUserId}/${targetUserId}/`; // ContentPage linki oluştur

  // QR kodu oluştur
  try {
    const qrCodeUrl = await QRCode.toDataURL(contentPageLink, { errorCorrectionLevel: 'H' });

    // Firestore'da user dokümanına link ve qr kodu ekle
    await setDoc(doc(firestore, "users", userId, "targetUsers", targetUserId), {
      contentPageLink,
      qrCodeUrl, // QR kodu base64 olarak ekleniyor
    }, { merge: true });

    return { contentPageLink, qrCodeUrl }; // Link ve QR kodu döndür
  } catch (error) {
    console.error("QR Code generation error:", error);
    throw new Error("Failed to generate QR code");
  }
};

// ContentPage Linkini çağırıp SubscriberComponent'e iletiyor.
export const getContentPageLinkAndQr = async (userId, targetUserId) => {
try {
  const userDoc = await getDoc(doc(firestore, "users", userId, "targetUsers", targetUserId));
  const contentPageLink = userDoc.data()?.contentPageLink;
  const contentPageQr = userDoc.data()?.qrCodeUrl ;
  return {contentPageLink , contentPageQr}
}
  catch (error) {
    console.error("ContentPage link firestore'dan alınırken hata oluştu:", error);
    throw error;
  }
}

// Check Subscription Status and Pending Requests
export const checkSubscriptionStatus = async (targetUserId) => {
  try {
    // Fetch all pending subscription requests
    const pendingRequests = await fetchPendingSubscriptionRequests();
   
    // Check if there is an active or pending request for the target user
    const existingPendingRequest = pendingRequests.find(request => {
      return request.targetUserId === targetUserId;
    });
    
    if (existingPendingRequest) {
      return true
    } else {
      return false
    }
  
  } catch (error) {
    console.error("Error checking subscription status:", error);
    throw new Error("Could not check subscription status");
  }
};

// Fetch all current subscribers
export const fetchSubscribers = async (userId) => {
  console.log("userId, targetUserId : ", userId)
  const subscribersCollection = collection(firestore, `users/${userId}/targetUsers`);
  const snapshot = await getDocs(subscribersCollection);
  
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};




//KREDİ BÖLÜMÜ BAŞLANGICI//
//Kredi modülünü kaldırdığımız için bu kısmı kontrol et ve sil.
// Update user credits in Firestore
export const updateUserCredits = async (userId, creditsToAdd) => {
  try {
    const userRef = doc(firestore, "users", userId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      await updateDoc(userRef, {
        credits: increment(creditsToAdd)
      });
      console.log(`${creditsToAdd} credits updated.`);
    } else {
      console.error("User not found.");
    }
  } catch (error) {
    console.error("Error updating user credits:", error);
    throw error;
  }
};

// Fetch pending credit requests from Firestore
export const fetchPendingCreditRequests = async () => {
  try {
    const querySnapshot = await getDocs(
      query(collection(firestore, "creditRequests"), orderBy("requestDate"))
    );
    return querySnapshot.docs
      .filter((doc) => doc.data().status === "pending")
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  } catch (error) {
    console.error("Error fetching pending credit requests:", error);
    throw error;
  }
};

// Approve a credit request by updating its status and user credits
export const approveCreditRequest = async (requestId, userId, creditsRequested) => {
  try {
    const requestRef = doc(firestore, "creditRequests", requestId);
    await updateDoc(requestRef, { status: "approved" });
    await updateUserCredits(userId, creditsRequested);
    console.log(`Credit request approved: ${requestId}`);
  } catch (error) {
    console.error("Error approving credit request:", error);
    throw error;
  }
};

// Fetch user credits from Firestore
export const fetchUserCredits = async (uid) => {
  try {
    const userRef = doc(firestore, "users", uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();
      console.log("Fetched User Data:", userData);
      return userData.credits ?? 0;
    } else {
      console.error("User not found.");
      return 0;
    }
  } catch (error) {
    console.error("Error fetching user credits:", error);
    throw error;
  }
};

// Request credit approval
export const requestCreditApproval = async (userId, creditsRequested) => {
  try {
    const requestId = uuidv4(); // Generate a unique request ID
    await addDoc(collection(firestore, "creditRequests"), {
      userId,
      creditsRequested,
      status: "pending",
      requestDate: new Date(),
      requestId
    });
    console.log("Credit request sent:", requestId);
  } catch (error) {
    console.error("Error requesting credit approval:", error);
  }
};

//KREDİ BÖLÜMÜ BİTİŞİ//