export const actionType = {
    SET_PRODUCTS : 'SET_PRODUCTS' ,
    SET_USER : ' SET_USER'
}

const reducer = (state, action) => {
    switch(action.type){
        case actionType.SET_PRODUCTS:
            return {
                ...state,
                products : action.products,
            };
        case actionType.SET_USER:
            return {
                ...state,
                user : action.user,
            };
  
        default : 
        return state;
    }

}

export default reducer;