import React, { useEffect, useState } from "react";
import { 
  approveSubscriptionRequest, 
  fetchPendingSubscriptionRequests, 
  cancelSubscriptionRequest,
  createUserContentPageLink  // createUserContentPageLink fonksiyonunu içe aktar
} from "../utils/FirebaseFunction";
import "../css/index.css";
import LoginForm from "./LoginForm";

function AdminPage() {
  const [subscriptionRequests, setSubscriptionRequests] = useState([]);

  // Bekleyen abonelik taleplerini getirme
  useEffect(() => {
    const getRequests = async () => {
      try {
        const requests = await fetchPendingSubscriptionRequests();
        console.log("requests : ", requests)

        setSubscriptionRequests(requests);
      } catch (error) {
        console.error("Abonelik talepleri alınırken hata oluştu:", error);
      }
    };
    getRequests();
  }, []);

  // Onay işlemi
  const handleApprove = async (requestId, userId, targetUserId, hashedUserId) => {
    try {
      // Abonelik talebini onayla
      await approveSubscriptionRequest(requestId, userId);
      
      // Kullanıcıya özel içerik sayfası linki oluştur
      const contentPageLink = await createUserContentPageLink(userId, hashedUserId, targetUserId);
      console.log("Kullanıcı İçerik Sayfası Linki:", contentPageLink);

      // Burada, içeriğe ait linki Firestore'a kaydedebilirsiniz
      // Örneğin: saveContentPageLinkToFirestore(userId, contentPageLink);

      // Abonelik talebini listeden çıkar
      setSubscriptionRequests(subscriptionRequests.filter((req) => req.id !== requestId));
    } catch (error) {
      console.error("Abonelik talebi onaylanırken hata oluştu:", error);
    }
  };

  // İptal işlemi
  const handleCancel = async (requestId) => {
    try {
      await cancelSubscriptionRequest(requestId);
      setSubscriptionRequests(subscriptionRequests.filter((req) => req.id !== requestId));
    } catch (error) {
      console.error("Abonelik talebi iptal edilirken hata oluştu:", error);
    }
  };

  return (
    <>
      <LoginForm buttonClass="absolute top-2 right-2" />
      <div className="admin-page p-6">
  <h2 className="text-2xl font-bold mb-4">Abonelik Talepleri</h2>
  {subscriptionRequests.length > 0 ? (
    subscriptionRequests.map((req) => {
      console.log("Subscription Request:", req); // Loglama burada yapılır
      return (
        <div key={req.id} className="subscription-request border p-4 mb-4 rounded shadow">
          <p><strong>Talep eden kullanıcı ID:</strong> {req.requesterId}</p>
          <p><strong>Talep eden kullanıcı İsmi/Soyismi:</strong> {req.requesterName}</p>
          <p><strong>Talep edilen kullanıcı İsmi/Soyismi:</strong> {req.targetUserName} {req.targetUserSurname}</p>
          
          <button
            onClick={() => handleApprove(req.id, req.requesterId, req.targetUserId, req.hashedUserId)}
            className="bg-blue-500 text-white mt-2 px-4 py-2 mr-2 rounded hover:bg-blue-600"
          >
            Onayla
          </button>
          <button
            onClick={() => handleCancel(req.id)}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            İptal Et
          </button>
        </div>
      );
    })
  ) : (
    <p>Bekleyen abonelik talebi yok.</p>
  )}
</div>

    </>
  );
}

export default AdminPage;
